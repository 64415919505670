// complete ES6 polyfill for all older browsers & devices
import "@babel/polyfill";
import '@ihme/common/polyfills';
// hot loader helper
import 'react-hot-loader';

import React from 'react';
import ReactDOM from 'react-dom';

import ThemeProvider from '@ihme/common/theme/ThemeProvider';
import { GoogleAnalyticsProvider } from '@ihme/common/packages/google-analytics';
import config from './config';

import { init as initLocales } from './locale';
import defaultTheme from './theme/default';
import Root from './components/Root';

initLocales();

ReactDOM.render(
    <ThemeProvider theme={defaultTheme}>
        <GoogleAnalyticsProvider
            googleAnalyticsId={config.googleAnalyticsId}
            debug={config.googleAnalyticsDebug}>
            <Root />
        </GoogleAnalyticsProvider>
    </ThemeProvider>,
    document.getElementById('root')
);
