import React from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import numeral from 'numeral';
import uniqBy from 'lodash/uniqBy';

import _ from '@ihme/common/locale';
import styled from '@ihme/common/theme/styled';
import {
    Col,
    ChartButtonSelect,
    ChartDropdown,
    ChartProvider,
    Clearfix,
    Form,
    LineChart,
    PageHeader,
} from '@ihme/common/web/components';
import echartsTheme from '@ihme/common/theme/echarts-theme';

import config from '../../config';
import locale from './locale';
import api from '../../api';
import {
    loadBurdenForecastsWithTHEFilters,
    loadLocations,
    loadSpendScenarios,
} from '../../store/data/actions';
import {
    getBurdenForecastsWithTHEFilters,
    getLocations,
    getSpendScenarios,
} from '../../store';
import { sortLocations } from '../../utility/sort-helpers';

const propTypes = {
    history: ReactRouterPropTypes.history.isRequired,
    location: ReactRouterPropTypes.location.isRequired,

    locations: PropTypes.array,
    loadLocations: PropTypes.func.isRequired,

    burdenForecastsWithTHEFilters: PropTypes.object,
    loadBurdenForecastsWithTHEFilters: PropTypes.func.isRequired,

    spendScenarios: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            key: PropTypes.string,
            global_fund_funding: PropTypes.string,
            pepfar_funding: PropTypes.string,
        }),
    ),
    loadSpendScenarios: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    locations: getLocations(state),
    burdenForecastsWithTHEFilters: getBurdenForecastsWithTHEFilters(state),
    spendScenarios: getSpendScenarios(state),
});

const DEFAULT_FILTERS = {
    metric_id: config.defaultSpendingScenariosMetricId,
    measure_id: config.defaultSpendingScenariosMeasureId,
    location_id: config.defaultSpendingScenariosLocationId,
    spend_scenario_id: config.defaultSpendScenarioId,
};

const ButtonGroup = styled('div')(({ theme }) => ({
    '> *': {
        width: 252,
        marginRight: 12,
    },
}));

const ScenariosButtonSelect = styled(ChartButtonSelect)(() => ({
    'button': {
        marginBottom: 10,
    },
}));

class SpendingImpactScene extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            filters: {},
            maxTHE: 0,
        };
    }

    componentWillMount() {
        if (!this.props.burdenForecastsWithTHEFilters) {
            this.props.loadBurdenForecastsWithTHEFilters();
        }

        if (!this.props.spendScenarios) {
            this.props.loadSpendScenarios();
        }

        if (!this.props.locations) {
            this.props.loadLocations();
        }
    }

    // restore filters from URL Params
    getInitialFilters = () => queryString.parse(this.props.location.search);

    onFiltersChange = (newFilters, allFilters) =>
        this.setState({ ...this.state, filters: allFilters });

    loadData = params => new Promise((resolve, reject) =>
        // to calculate max value we have to load data for all spend scenarios
        // (https://mistholding.atlassian.net/browse/HIV-95)
        api.data.getBurdenForecastsWithTHE({ ...params, spend_scenario_id: undefined })
            .then(response => {
                const { columns, records } = response;

                const meanIndex = columns.indexOf('mean');
                const theMeanIndex = columns.indexOf('the_mean');
                const upperIndex = columns.indexOf('upper');
                const lowerIndex = columns.indexOf('lower');
                const spendScenarioIndex = columns.indexOf('spend_scenario_id');

                this.setState({
                    maxTHE: Math.max(
                        ...records
                            .map(record =>
                                record[theMeanIndex])),
                });

                // to reflect the 100K rate data has to be multiplied by 100,000
                // (https://mistholding.atlassian.net/browse/HIV-82)
                const multiplier = 100000;

                resolve({
                    columns,
                    records: records
                        .filter(record =>
                            record[spendScenarioIndex] === Number(params.spend_scenario_id))
                        .map(record => {
                            const newRecord = [...record];
                            newRecord[meanIndex] = record[meanIndex] * multiplier;
                            newRecord[upperIndex] = record[upperIndex] * multiplier;
                            newRecord[lowerIndex] = record[lowerIndex] * multiplier;
                            return newRecord;
                        }),
                });

            })
            .catch(reject),
    );

    renderTooltip = (params, { filters, isMobileView }) => {
        params = uniqBy(params, record => record.seriesName);
        const tooltipFontSize = isMobileView ? 10 : 14;

        const getMarker = color =>
            `<span style="display:inline-block;margin-right:8px;border-radius:10px;width:10px;height:10px;background-color:${color};"></span>`;

        const formatValue = (value, useDollarSign) =>
            (useDollarSign ? '$' : '')
            + numeral(value)
                .format(
                    parseInt(value) > 0
                        ? '0,0.0a'
                        : '0.0[000000]')
                .toUpperCase();

        return `<div style="text-align:left;font-size:${tooltipFontSize}px">` +
            params.map(record =>
                getMarker(record.color.colorStops[0].color) +
                record.seriesName + ' in ' + record.axisValue + ':&nbsp;&nbsp;&nbsp;' +
                `<b>${formatValue(record.value, record.seriesName !== this.getMeanTitle())}</b>`)
                .join('<br/>') + '</div>';
    };

    getSaveFilename = ({ filters, yearsRange }) => 'Trends, '
        + _(`measure_${filters.measure_id}`) + ' '
        + _(`metric_${filters.metric_id}`) + ' in '
        + _(`location_${filters.location_id}`) + ', '
        + _(`spend_scenario_${filters.spend_scenario_id}`) + ', '
        + yearsRange.range;

    getMeanTitle = () =>
        this.state.filters.measure_id
            ? _(locale.meanTitle, {
                measure: _(`measure_${this.state.filters.measure_id}`),
            })
            : '';

    getCSVMeanTitle = () =>
        this.state.filters.measure_id
            ? _(locale.csvMeanTitle, {
                measure: _(`measure_${this.state.filters.measure_id}`),
            })
            : '';

    generateLegendData = () =>
        this.getLineParams()
            .map(params => ({
                ...params,
                icon: 'circle',
            }));

    getLineParams = () => [
        {
            name: this.getMeanTitle(),
            color: echartsTheme.line.colors[0],
        },
        {
            name: _(locale.theTitle),
            color: echartsTheme.line.colors[1],
        },
    ];

    getScenarioData = id =>
        this.props.spendScenarios
            ? this.props.spendScenarios
                .find(scenario =>
                    scenario.id === id)
            : {};

    dollarValueFormatter = value =>
        numeral(value)
            .format('$0,0a')
            .toUpperCase();

    render() {
        const {
            burdenForecastsWithTHEFilters,
            locations,
        } = this.props;

        return (
            <ChartProvider
                key={burdenForecastsWithTHEFilters}
                loadFilters={() => Promise.resolve(burdenForecastsWithTHEFilters)}
                loadData={this.loadData}
                defaultFilters={DEFAULT_FILTERS}
                initialFilters={this.getInitialFilters()}
                onFiltersChange={this.onFiltersChange}
                history={this.props.history}
                location={this.props.location}>
                <PageHeader>{_(locale.title)}</PageHeader>
                <Col xs={12} md={9} mdPush={3}>
                    <Form inline>
                        <ButtonGroup>
                            <ChartDropdown
                                label={_(locale.measuresTitle)}
                                localePrefix="measure_"
                                filterKey="measure_id"
                            />
                            <ChartDropdown
                                label={_(locale.locationsTitle)}
                                localePrefix="location_"
                                filterKey="location_id"
                                preprocessOptions={params => sortLocations(params, locations)}
                                isSearchable
                            />
                        </ButtonGroup>
                        <Clearfix />
                    </Form>

                    <LineChart
                        withSlider
                        saveAsImage={{ visible: true, filename: this.getSaveFilename }}
                        saveAsCSV={{
                            visible: true,
                            filename: this.getSaveFilename,
                            headers: [
                                'Location',
                                'Year',
                                'Unit',
                                'Measure',
                                _(locale.csvPPPTitle),
                                this.getCSVMeanTitle(),
                                `${this.getMeanTitle()} Upper Bound`,
                                `${this.getMeanTitle()} Lower Bound`,
                            ],
                            keys: [
                                'location_id',
                                'year',
                                'metric_id',
                                'measure_id',
                                'the_mean',
                                'mean',
                                'upper',
                                'lower',
                            ],
                        }}
                        yAxisSlitLinesAmount={5}
                        yAxisKeys={['mean']}
                        renderYAxisTitle={({ filters }) =>
                            _(locale.measureAxisTitle, { measure: _(`measure_${filters.measure_id}`) })}
                        yRightAxisKeys={['the_mean']}
                        yRightAxisMaxValue={this.state.maxTHE}
                        renderYRightAxisTitle={() => _(locale.pppAxisTitle)}
                        yRightAxisLabelFormatter={this.dollarValueFormatter}
                        markLineValue={2017}
                        lineParams={this.getLineParams()}
                        renderTooltip={this.renderTooltip}
                        renderTitle={({ filters, yearsRange }) =>
                            _(`measure_${filters.measure_id}`) + ' '
                            + 'Rate in '
                            + _(`location_${filters.location_id}`) + ' '
                            + yearsRange.range}
                        renderSubtitle={({ filters }) =>
                            _(`spend_scenario_${filters.spend_scenario_id}`)}
                        generateLegendData={this.generateLegendData}
                    />
                </Col>
                <Col xs={12} md={3} mdPull={9}>
                    <p dangerouslySetInnerHTML={{ __html: _(locale.highlightText) }} />
                    <ScenariosButtonSelect
                        label={'silly'}
                        localePrefix="spend_scenario_"
                        filterKey="spend_scenario_id"
                        renderOption={(key, value) => {
                            const {
                                global_fund_funding,
                                pepfar_funding,
                            } = this.getScenarioData(key);

                            return (
                                <div style={{ textAlign: 'left', fontSize: 12, marginBottom: 0 }}>

                                    <b>{`${value}`}</b>
                                    <table style={{ width: '100%' }}>
                                        <tbody>
                                            <tr>
                                                <td>Global Fund Funding:</td>
                                                <td style={{ width: '28%' }}>{global_fund_funding}</td>
                                            </tr>
                                            <tr>
                                                <td>PEPFAR Funding:</td>
                                                <td>{pepfar_funding}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            );
                        }}
                    />
                </Col>
            </ChartProvider>
        );
    }
}

SpendingImpactScene.propTypes = propTypes;

export default compose(
    withRouter,
    connect(mapStateToProps, {
        loadLocations,
        loadBurdenForecastsWithTHEFilters,
        loadSpendScenarios,
    }),
)(SpendingImpactScene);
