import React from 'react';

import _ from '@ihme/common/locale';
import { PageHeader } from '@ihme/common/web/components';

import locale from './locale';

const styles = {
    header: {
        paddingLeft: 0,
    },
    container: {
        maxWidth: 540,
        margin: '0 auto',
    },
};

const AboutScene = () =>
    <div style={styles.container}>
        <PageHeader style={styles.header}>{_(locale.title)}</PageHeader>
        <div dangerouslySetInnerHTML={{ __html: _(locale.text) }}/>
    </div>;

export default AboutScene;
