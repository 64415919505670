import * as types from './types';

const initialState = {
    hivGbdFilters: null,
    causes: null,
    locations: null,
    burdenForecastFilters: null,
    burdenForecastsWithTHEFilters: null,
    spendingForecastFilters: null,
    spendScenarios: null,
    spendCategories: null,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case types.HIV_GBD_FILTERS_LOADED:
            return { ...state, hivGbdFilters: action.payload };

        case types.CAUSES_LOADED:
            return { ...state, causes: action.payload };

        case types.LOCATIONS_LOADED:
            return { ...state, locations: action.payload };

        case types.BURDEN_FORECAST_FILTERS_LOADED:
            return { ...state, burdenForecastFilters: action.payload };

        case types.BURDEN_FORECASTS_WITH_THE_FILTERS_LOADED:
            return { ...state, burdenForecastsWithTHEFilters: action.payload };

        case types.SPENDING_FORECAST_FILTERS_LOADED:
            return { ...state, spendingForecastFilters: action.payload };

        case types.SPEND_SCENARIOS_LOADED:
            return { ...state, spendScenarios: action.payload };

        case types.SPEND_CATEGORIES_LOADED:
            return { ...state, spendCategories: action.payload };

        default:
            return state;
    }
}

export const getCauses = state => state.causes;
export const getHivGbdFilters = state => state.hivGbdFilters;
export const getLocations = state => state.locations;
export const getBurdenForecastFilters = state => state.burdenForecastFilters;
export const getBurdenForecastsWithTHEFilters = state => state.burdenForecastsWithTHEFilters;
export const getSpendingForecastFilters = state => state.spendingForecastFilters;
export const getSpendScenarios = state => state.spendScenarios;
export const getSpendCategories = state => state.spendCategories;
