import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';

export const sortLocations = (locationsToSort, locationsData) => {
    const getLocationType = id => {
        let type = 9;
        if (locationsData) {
            const locationData = locationsData.find(location => location.id === id);
            if (locationData && locationData.type_id !== 2 && locationData.type_id !== 8) {
                type = locationData.type_id;
            }
        }

        return type;
    };

    return sortBy(
        uniqBy(locationsToSort, 'label')
            .map(location => ({
                ...location,
                type: getLocationType(location.value),
            })), ['type', 'label']);
};
