export default {
    title: 'spending_impact_scene_title',
    highlightText: 'spending_impact_highlight_text',
    theTitle: 'spending_impact_the_title',
    meanTitle: 'spending_impact_mean_title',
    measuresTitle: 'filter_measure_title',
    locationsTitle: 'filter_location_title',
    csvMeanTitle: 'spending_impact_csv_mean_header',
    csvPPPTitle: 'spending_impact_csv_ppp_header',
    pppAxisTitle: 'spending_impact_ppp_axis_title',
    measureAxisTitle: 'spending_impact_measure_axis_title',
};
