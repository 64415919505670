import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';

import { Nav, Navbar, NavItem } from '@ihme/common/web/components';
import styled from '@ihme/common/theme/styled';

import NavDropdown from 'react-bootstrap/lib/NavDropdown';

const propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        action: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.func,
        ]),
        subItems: PropTypes.arrayOf(PropTypes.shape({
            key: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            action: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.func,
            ]).isRequired,
        })),
    })).isRequired,
    style: PropTypes.object,
};

const Logo = styled.img(({ theme }) => ({
    display: 'inline',
    marginLeft: theme.spacing.horizontal.sm,
    height: 26,
    marginTop: -4,
    [theme.breakpoint.sm]: {
        height: 26,
        marginTop: 6,
    },
}));

class View extends React.PureComponent {
    renderNavigation = () =>
        <Navbar.Collapse>
            <Nav pullRight>
                {this.props.items.map(item =>
                    item.subItems
                        ? this.renderDropDown(item)
                        : this.renderNavItem(item))}
            </Nav>
        </Navbar.Collapse>;

    renderDropDown = menu =>
        <NavDropdown id="nav-dropdown" key={menu.key} title={menu.title}>
            {menu.subItems.map(item => this.renderNavItem(item))}
        </NavDropdown>;

    renderNavItem = item => {
        const isHttpLink = item.action.indexOf('http://') !== -1 || item.action.indexOf('https://') !== -1;

        return isHttpLink
            ? (
                <NavItem
                    disabled={item.disabled}
                    key={item.key}
                    href={item.action}
                    target="_blank">
                    {item.title}
                </NavItem>
            )
            : (
                <LinkContainer exact key={item.key} to={item.action}>
                    <NavItem>{item.title}</NavItem>
                </LinkContainer>
            );
    };

    render() {
        return (
            <Navbar collapseOnSelect style={this.props.style}>
                <Navbar.Header>
                    <Navbar.Brand>
                        <Link to="/">
                            <Logo
                                src="/img/logo.png"
                                alt="IHME HIV Atlas"
                            />
                        </Link>
                    </Navbar.Brand>
                    <Navbar.Toggle/>
                </Navbar.Header>
                {this.renderNavigation()}
            </Navbar>
        );
    }
}

View.propTypes = propTypes;

export default View;
