import React from 'react';
import PropTypes from 'prop-types';

import styled from '@ihme/common/theme/styled';
import { Grid } from '@ihme/common/web/components';

import _ from '@ihme/common/locale';
import locale from '../HomeScene/locale';

import Navigation from '../Navigation';
import NewsList from '../NewsList';

const propTypes = {
    children: PropTypes.any,
    isFooterHidden: PropTypes.bool,
};

const NavbarContainer = styled.div(({ theme }) => ({
    marginTop: 40,
    marginBottom: 60,
    [theme.breakpoint.xs]: {
        marginTop: 0,
        marginBottom: 10,
    },
}));

const BackgroundGradient = styled.div(({ theme }) => ({
    marginTop: 20,
    background: 'linear-gradient(-138deg, #199949 0%, #20C05C 100%)',
    color: '#fff',
}));

const Footer = styled.footer(({ theme }) => ({
    textAlign: 'center',
    fontFamily: theme.typography.fontFamily.sansSerif,
    fontSize: theme.typography.fontSize.md,
    marginTop: 50,
    marginBottom: 50,
    '& a': {
        color: '#fff',
    },
    '& a:hover': {
        color: '#f5f7f8',
    },
    [theme.breakpoint.xs]: {
        marginTop: 20,
        marginBottom: 20,
    },
}));

const Layout = props =>
    <div>
        <NavbarContainer>
            <Navigation style={{ boxShadow: 'none' }}/>
        </NavbarContainer>

        <Grid>
            {props.children}
        </Grid>

        <BackgroundGradient>
            <Grid>
                <NewsList/>
                {!props.isFooterHidden &&
                <Footer dangerouslySetInnerHTML={{ __html: _(locale.footer) }}/>
                }
            </Grid>
        </BackgroundGradient>
    </div>;

Layout.propTypes = propTypes;

export default Layout;
