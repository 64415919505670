import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import _ from '@ihme/common/locale';

import locale from './locale';

import {
    HOME_PATH,
    GLOBAL_IMPACT_PATH,
    TRENDS_PATH,
    SPENDING_IMPACT_PATH,
    ABOUT_PATH,
    HEPATITIS_PATH,
} from '../../router/paths';

import View from './View';

const contextTypes = {
    router: PropTypes.object.isRequired,
};

class Navigation extends React.PureComponent {
    render() {
        return (
            <View
                {...this.props}
                items={[
                    {
                        key: 'home',
                        action: HOME_PATH,
                        title: _(locale.home),
                    },
                    {
                        key: 'globalImpact',
                        action: GLOBAL_IMPACT_PATH,
                        title: _(locale.globalImpact),
                    },
                    {
                        key: 'trends',
                        action: TRENDS_PATH,
                        title: _(locale.trends),
                    },
                    /*{
                        key: 'globalSpending',
                        action: GLOBAL_SPENDING_PATH,
                        title: _(locale.globalSpending),
                    },*/
                    {
                        key: 'fundingScenarios',
                        action: SPENDING_IMPACT_PATH,
                        title: _(locale.spendingImpact),
                    },
                    {
                        key: 'about',
                        action: ABOUT_PATH,
                        title: _(locale.about),
                    },
                    {
                        key: 'moreStories',
                        title: _(locale.moreStories),
                        subItems: [
                            {
                                key: 'hepatitis',
                                action: HEPATITIS_PATH,
                                title: _(locale.hepatitis),
                            },
                        ],
                    },
                ]}
            />
        );
    }
}

Navigation.contextTypes = contextTypes;

export default withRouter(Navigation);
