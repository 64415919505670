import React from 'react';

import _ from '@ihme/common/locale';
import styled from '@ihme/common/theme/styled';
import { Grid, InternalLink } from '@ihme/common/web/components';

import locale from './locale';
import { SPENDING_IMPACT_PATH } from '../../router/paths';

import ChartList from './ChartList';
import HighlightsList from './HighlightsList';
import Navigation from '../Navigation';
import NewsList from '../NewsList';

const BackgroundGradient = styled.div(props => ({
    background: 'linear-gradient(-138deg, #199949 0%, #20C05C 100%)',
    color: '#fff',
}));

const BackgroundMainImage = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '600px',
    paddingTop: 40,
    backgroundImage: 'url(/img/Top-Image.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
}));

const Hero = styled.div(({ theme }) => ({
    marginTop: 60,
    marginBottom: 40,
    [theme.breakpoint.lg]: {
        marginTop: 100,
    },
}));

const HeroText = styled.div(({ theme }) => ({
    maxWidth: 700,
    fontFamily: theme.typography.fontFamily.sansSerif,
    fontSize: 16,
    lineHeight: 22 / 14,
    fontWeight: 300,
    marginBottom: 40,
}));

const GlobalSpendingButton = styled.div(({ theme }) => ({
    color: '#ffffff',
    display: 'inline-block',
    fontSize: 16,
    fontWeight: theme.typography.fontWeight.bold,
    borderRadius: 4,
    backgroundColor: '#199949',
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2)',
    padding: '15px 32px',
    letterSpacing: 0.2,
    ':hover': {
        backgroundColor: '#168941',
    },
}));

const Footer = styled.footer(({ theme }) => ({
    color: '#FFFFFF',
    textAlign: 'center',
    fontFamily: theme.typography.fontFamily.sansSerif,
    fontSize: theme.typography.fontSize.md,
    marginTop: 50,
    marginBottom: 50,
    '& a': {
        color: '#FFFFFF',
    },
    '& a:hover': {
        color: '#f5f7f8',
    },
    [theme.breakpoint.xs]: {
        marginTop: 20,
        marginBottom: 20,
    },
}));

class HomeScene extends React.Component {
    render() {
        return (
            <React.Fragment>
                <BackgroundMainImage>
                    <div>
                        <Navigation/>
                    </div>
                    <Grid>
                        <Hero>
                            <h1>{_(locale.title)}</h1>
                            <HeroText dangerouslySetInnerHTML={{ __html: _(locale.subTitle) }}/>
                            <InternalLink href={SPENDING_IMPACT_PATH}>
                                <GlobalSpendingButton>
                                    {_(locale.mainButton)}
                                </GlobalSpendingButton>
                            </InternalLink>
                        </Hero>
                    </Grid>
                </BackgroundMainImage>

                <Grid>
                    <ChartList/>
                    <HighlightsList/>
                </Grid>

                <BackgroundGradient>
                    <Grid>
                        <NewsList/>
                        <Footer dangerouslySetInnerHTML={{ __html: _(locale.footer) }}/>
                    </Grid>
                </BackgroundGradient>
            </React.Fragment>
        );
    }
}

export default HomeScene;
