import React from 'react';

import _ from '@ihme/common/locale';
import styled from '@ihme/common/theme/styled';
import { ExternalLink } from '@ihme/common/web/components';

import locale from './locale';

import NewsItem from './NewsItem';

const Header = styled.h4(({ theme }) => ({
    marginTop: 80,
    marginBottom: 40,
    textAlign: 'left',
    [theme.breakpoint.xs]: {
        marginTop: 40,
        marginBottom: 20,
    },
}));

class NewsList extends React.PureComponent {
    getData = () => [
        {
            key: 0,
            title: _(locale.link0),
            link: 'https://www.thelancet.com/journals/lancet/article/PIIS0140-6736(18)30698-6/fulltext',
        },
        {
            key: 1,
            title: _(locale.link1),
            link: 'http://www.healthdata.org/infographic/declines-hivaids-funding-put-poorest-countries-risk',
        },
        {
            key: 2,
            title: _(locale.link2),
            link: 'http://ihmeuw.org/4g6n',
        },
    ];

    render() {
        return (
            <React.Fragment>
                <Header>
                    {_(locale.newsTitle)}
                </Header>

                {this.getData().map(item =>
                    <ExternalLink href={item.link} key={item.key}>
                        <NewsItem>{item.title}</NewsItem>
                    </ExternalLink>,
                )}
            </React.Fragment>
        );
    }
}

export default NewsList;
