import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import { loadHivGbdFilters, loadCauses, loadLocations } from '../../store/data/actions';
import { getHivGbdFilters, getCauses, getLocations } from '../../store';
import _ from '@ihme/common/locale';
import styled from '@ihme/common/theme/styled';
import {
    Col,
    PageHeader,
    Clearfix,
    Form,
    MapChart,
    MapChartZoomControls,
    ChartProvider,
    ChartDropdown,
} from '@ihme/common/web/components';

import api from '../../api';
import config from '../../config';

import locale from './locale';

const ButtonGroup = styled('div')(({ theme }) => ({
    '> *': {
        width: 124,
        marginRight: 12,
        [theme.breakpoint.xs]: {
            width: 252,
        },
    },
    '> :first-child': { width: 252 },
}));

const StyledMapChartZoomControls = styled(MapChartZoomControls)(() => ({
    marginTop: 10,
    marginBottom: 10,
    float: 'right',
    'button + button': {
        marginLeft: 10,
    },
}));

const DEFAULT_FILTERS = {
    age_group_id: config.defaultGlobalImpactAgeGroupId,
    gender_id: config.defaultGlobalImpactGenderId,
    measure_id: config.defaultGlobalImpactMeasureId,
    metric_id: config.defaultGlobalImpactMetricId,
    cause_id: config.defaultGlobalImpactCauseId,
};

class GlobalImpactScene extends React.PureComponent {
    static propTypes = {
        hivGbdFilters: PropTypes.shape({
            location_id: PropTypes.arrayOf(PropTypes.number),
            gender_id: PropTypes.arrayOf(PropTypes.number),
            age_group_id: PropTypes.arrayOf(PropTypes.number),
            measure_id: PropTypes.arrayOf(PropTypes.number),
            metric_id: PropTypes.arrayOf(PropTypes.number),
            year: PropTypes.arrayOf(PropTypes.number),
        }),
        causes: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number,
                name: PropTypes.string,
                level: PropTypes.number,
                level0_parent_id: PropTypes.number,
                level1_parent_id: PropTypes.number,
                level2_parent_id: PropTypes.number,
                level3_parent_id: PropTypes.number,
            }),
        ),
        locations: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number,
                name: PropTypes.string,
                level: PropTypes.number,
                level0_parent_id: PropTypes.number,
                level1_parent_id: PropTypes.number,
                level2_parent_id: PropTypes.number,
                level3_parent_id: PropTypes.number,
            }),
        ),
    };

    constructor(props) {
        super(props);

        this.state = {
            filters: {},
            year: config.defaultGlobalImpactYear,
        };
    }

    componentWillMount() {
        if (!this.props.hivGbdFilters) {
            this.props.loadHivGbdFilters();
        }

        if (!this.props.causes) {
            this.props.loadCauses();
        }

        if (!this.props.locations) {
            this.props.loadLocations();
        }
    }

    onFiltersChange = (newFilters, allFilters) =>
        this.setState({ ...this.state, filters: allFilters });

    onSliderChange = sliderValue => this.setState({ year: sliderValue });

    renderTooltip = params => {
        const causeText = _(`cause_${this.state.filters.cause_id}`) + ` in ${this.state.year}:   `;
        return '<span style="color:black"><b>' + params.data.name + '</b><br/><br/>'
            + causeText + '<b>' + params.value.toFixed(2) + '</b></span>';
    };

    getInitialFilters = () => queryString.parse(this.props.location.search);

    getSaveFilename = ({ filters }) => (
        'GlobalImpact, ' +
        _(`cause_${filters.cause_id}`) + ' ' +
        _(`measure_${filters.measure_id}`) + ' ' +
        _(`metric_${filters.metric_id}`) + ', ' +
        _(`age_group_${filters.age_group_id}`) + ', ' +
        _(`gender_${filters.gender_id}`)
    );

    getLocationFilter = () => {
        const { locations } = this.props;
        if (!locations) {
            return;
        }

        return locations
            .filter(i => [2, 8].includes(i.type_id))
            .map(i => i.id)
            .join(',');
    };

    render() {
        const { hivGbdFilters } = this.props;
        const location_id = this.getLocationFilter();

        return (
            <React.Fragment>
                <PageHeader>{_(locale.title)}</PageHeader>
                <Col xs={12} md={9} mdPush={3}>
                    <ChartProvider
                        key={hivGbdFilters}
                        loadFilters={() => Promise.resolve(hivGbdFilters)}
                        loadData={filters => api.data.getHivGbdRecords({ ...filters, location_id })}
                        defaultFilters={DEFAULT_FILTERS}
                        initialFilters={this.getInitialFilters()}
                        history={this.props.history}
                        onFiltersChange={this.onFiltersChange}
                        location={this.props.location}>

                        <Form inline>
                            <ButtonGroup>
                                <ChartDropdown
                                    label={_(locale.causesTitle)}
                                    localePrefix="cause_"
                                    filterKey="cause_id"
                                    isSearchable
                                />
                                <ChartDropdown
                                    label={_(locale.metricsTitle)}
                                    localePrefix="metric_"
                                    filterKey="metric_id"
                                />
                                <ChartDropdown
                                    label={_(locale.measuresTitle)}
                                    localePrefix="measure_"
                                    filterKey="measure_id"
                                />
                                <ChartDropdown
                                    label={_(locale.ageGroupsTitle)}
                                    localePrefix="age_group_"
                                    filterKey="age_group_id"
                                />
                                <ChartDropdown
                                    label={_(locale.gendersTitle)}
                                    localePrefix="gender_"
                                    filterKey="gender_id"
                                />
                            </ButtonGroup>

                            <StyledMapChartZoomControls />
                            <Clearfix />
                        </Form>

                        <MapChart
                            valueKey="mean"
                            enableSliderWithFilterKey="year"
                            initialSliderValue={config.defaultGlobalImpactYear}
                            onSliderChange={this.onSliderChange}
                            renderTooltip={this.renderTooltip}
                            renderTitle={({ filters, sliderValue }) => (
                                _(`cause_${filters.cause_id}`) + ' ' +
                                _(`measure_${filters.measure_id}`) + ' ' +
                                _(`metric_${filters.metric_id}`) + ', ' + sliderValue
                            )}
                            renderSubtitle={({ filters }) => (
                                _(`age_group_${filters.age_group_id}`) + ', ' +
                                _(`gender_${filters.gender_id}`)
                            )}
                            saveAsImage={{
                                visible: true,
                                filename: this.getSaveFilename,
                            }}
                            saveAsCSV={{
                                visible: true,
                                filename: this.getSaveFilename,
                                headers: ['Location', 'Year', 'Age', 'Gender', 'Unit', 'Measure', 'Mean', 'Upper Bound', 'Lower Bound'],
                                keys: ['location_id', 'year', 'age_group_id', 'gender_id', 'metric_id', 'measure_id', 'mean', 'upper', 'lower'],
                            }}
                        />
                    </ChartProvider>
                </Col>
                <Col xs={12} md={3} mdPull={9}>
                    <p dangerouslySetInnerHTML={{ __html: _(locale.highlightText) }} />
                </Col>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    hivGbdFilters: getHivGbdFilters(state),
    causes: getCauses(state),
    locations: getLocations(state),
});

export default compose(
    withRouter,
    connect(mapStateToProps, { loadHivGbdFilters, loadCauses, loadLocations }),
)(GlobalImpactScene);
