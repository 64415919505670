import React from 'react';

import _ from '@ihme/common/locale';
import styled from '@ihme/common/theme/styled';
import { Grid } from '@ihme/common/web/components';

import locale from './locale';
import HighlightItem from './HighlightItem';

const Header = styled('h4')(({ theme }) => ({
    textAlign: 'left',
    marginTop: 80,
    [theme.breakpoint.xs]: {
        marginTop: 20,
        marginBottom: 20,
    },
}));

export default class HighlightsList extends React.PureComponent {
    generateData() {
        return [
            {
                key: 0,
                iconSrc: '/img/what_is_hiv_aids.png',
                title: _(locale.whatIsHepTitle),
                description: _(locale.whatIsHepDescription),
                text: _(locale.whatIsHepText),
            },
            {
                key: 1,
                iconSrc: '/img/funding.png',
                title: _(locale.fundingTitle),
                description: _(locale.fundingDescription),
                text: _(locale.fundingText),
            },
            {
                key: 2,
                iconSrc: '/img/burden.png',
                title: _(locale.burdenTitle),
                description: _(locale.burdenDescription),
                text: _(locale.burdenText),
            },
        ];
    }

    render() {
        return (
            <Grid>
                <Header>{_(locale.title)}</Header>

                {this.generateData()
                    .map(item =>
                        <HighlightItem
                            key={item.key}
                            description={item.description}
                            text={item.text}
                            title={item.title}
                            iconSrc={item.iconSrc}
                        />,
                    )}
            </Grid>
        );
    }
}
