export default {
    mapTitle: 'feature_bar_map_title',
    mapText: 'feature_bar_map_text',
    trendsTitle: 'feature_bar_trends_title',
    trendsText: 'feature_bar_trends_text',
    globalImpactTitle: 'feature_bar_global_impact_title',
    globalImpactText: 'feature_bar_global_impact_text',
    /*globalSpendingTitle: 'feature_bar_global_spending_title',
    globalSpendingText: 'feature_bar_global_spending_text',*/
};
