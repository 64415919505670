import extend from 'lodash/extend';

import { Filters } from '@ihme/common/types';
import api from '@ihme/common/api';
import request from '@ihme/common/api-request';
import config from './config';

api.setApiUrl(config.apiUrl);

export default extend(api, {
    data: {
        getCauses: api.getCauses,
        getLocations: () => request.get('locations'),
        getHivGbdFilters: () => request.get('data/hiv-gbd-filters', null, { timeout: 100000 }),

        // { location_id, age_group_id, gender_id, measure_id, metric_id, year }
        getHivGbdRecords: (filters: Filters) =>
            request.get('data/hiv-gbd', filters, { timeout: 30000 }),

        getBurdenForecastFilters: () => request.get('data/burden-forecast-filters'),
        getBurdenForecastsWithTHEFilters: () => request.get('data/burden-forecast-with-the-filters'),
        // { location_id, spend_scenario_id, measure_id, metric_id }
        getBurdenForecastsWithTHE: (filters: Filters) => request.get('data/burden-forecasts-with-the', filters, { timeout: 30000 }),
        getSpendingForecastFilters: () => request.get('data/spending-forecast-filters'),
        getSpendingForecastRecords: (filters: Filters) =>
            request.get('data/spending-forecasts', filters, { timeout: 30000 }),
        getBurdenForecasts: (filters: Filters) => request.get('data/burden-forecasts', filters, { timeout: 30000 }),
    },

    getSpendScenarios: () => request.get('/spend-scenarios'),
    getSpendCategories: () => request.get('/spend-categories'),
});
