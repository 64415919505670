import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import { withTrackedSceneView } from '@ihme/common/packages/google-analytics';

import Layout from '../components/Layout';
import AboutScene from '../components/AboutScene';
import GlobalImpactScene from '../components/GlobalImpactScene';
import HomeScene from '../components/HomeScene';
import SpendingImpactScene from '../components/SpendingImpactScene';
import TrendsScene from '../components/TrendsScene';

import {
    ABOUT_PATH,
    GLOBAL_IMPACT_PATH,
    HOME_PATH,
    SPENDING_IMPACT_PATH,
    TRENDS_PATH,
} from './paths';

const LayoutRoute = ({ component: Component, isFooterHidden, ...rest }) =>
    <Route
        {...rest}
        render={props => (
            <Layout isFooterHidden={isFooterHidden}>
                <Component {...props} />
            </Layout>
        )}
    />;

export default () => (
    <Router>
        <div>
            <Route
                path={HOME_PATH}
                component={withTrackedSceneView(HomeScene)}
                exact
            />
            <LayoutRoute
                path={ABOUT_PATH}
                component={withTrackedSceneView(AboutScene)}
                exact
            />
            <LayoutRoute
                path={GLOBAL_IMPACT_PATH}
                component={withTrackedSceneView(GlobalImpactScene)}
                exact
                isGrey
            />
            <LayoutRoute
                path={TRENDS_PATH}
                component={withTrackedSceneView(TrendsScene)}
                exact
            />
            {/*<LayoutRoute
                path={GLOBAL_SPENDING_PATH}
                component={withTrackedSceneView(GlobalSpendingScene)}
                exact
            />*/}
            <LayoutRoute
                path={SPENDING_IMPACT_PATH}
                component={withTrackedSceneView(SpendingImpactScene)}
                exact
            />
        </div>
    </Router>
);
