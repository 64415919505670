export default {
    home: 'navigation_home',
    globalImpact: 'navigation_global_impact',
    trends: 'navigation_trends',
    //globalSpending: 'navigation_global_spending',
    spendingImpact: 'navigation_spending_impact',
    about: 'navigation_about',
    moreStories: 'navigation_more_stories',
    phc: 'navigation_phc',
    hepatitis: 'navigation_hepatitis',
};
