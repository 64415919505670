import PropTypes from 'prop-types';
import React from 'react';

import _ from '@ihme/common/locale';
import styled from '@ihme/common/theme/styled';
import { Col, Row } from '@ihme/common/web/components';

import locale from './locale';
import Collapse from 'react-bootstrap/lib/Collapse';

const propTypes = {
    description: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    iconSrc: PropTypes.string.isRequired,
};

const Article = styled('article')(() => ({
    marginBottom: 30,
}));

const ArticleTextContainer = styled('div')(() => ({
    backgroundColor: 'white',
    marginBottom: 20,
    minHeight: 134,
    cursor: 'pointer',
}));

const Image = styled('img')(() => ({
    width: '100%',
    borderRadius: 4,
    backgroundColor: '#f5f7f8',
    cursor: 'pointer',
    ':hover': {
        opacity: 0.8,
    },
}));

const Description = styled('p')(() => ({
    marginBottom: 10,
}));

export default class HighlightItem extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
        };
    }

    onToggle = () => this.setState({ open: !this.state.open });

    render() {
        return (
            <Row>
                <Col sm={3}>
                    <Image src={this.props.iconSrc} onClick={this.onToggle}/>
                </Col>
                <Col sm={9}>
                    <Article>
                        <h5>{this.props.title}</h5>
                        <ArticleTextContainer onClick={this.onToggle}>
                            <Description dangerouslySetInnerHTML={{ __html: this.props.description }}/>
                            {!this.state.open && (
                                <a>{_(locale.more)}</a>
                            )}

                            <Collapse in={this.state.open} onClick={this.onToggle}>
                                <div>
                                    <div dangerouslySetInnerHTML={{ __html: this.props.text }}/>
                                    {this.state.open &&
                                    <a>{_(locale.less)}</a>
                                    }
                                </div>
                            </Collapse>
                        </ArticleTextContainer>
                    </Article>
                </Col>
            </Row>
        );
    }
}

HighlightItem.propTypes = propTypes;
