export const apiUrl = 'https://hiv-api.ihme.services';
export const appName = 'IHME HIV Atlas';
export const storageVersion = '0.0.5';

export const googleAnalyticsId = 'UA-118229877-6';
export const googleAnalyticsDebug = false;

export const numberMetricId = 1;
export const rateMetricId = 3;

export const deathMeasureId = 1;
export const dalyMeasureId = 2;
export const incidenceMeasureId = 6;

export const allAgesGroupId = 22;

export const maleGenderId = 1;
export const femaleGenderId = 2;
export const bothGenderId = 3;
export const allGendersId = [maleGenderId, femaleGenderId]
    .join(',');

export const allCausesCauseId = 294;
export const hivAidsCauseId = 298;
export const drugSusceptibleHivAidsCauseId = 948;

export const globalLocationId = 1;
export const highMiddleSdiLocationId = 44634;
export const highSdiLocationId = 44635;
export const lowMiddleSdiLocationId = 44636;
export const lowSdiLocationId = 44637;
export const middleSdiLocationId = 44639;
export const USALocationId = 102;
export const southAfricaLocationId = 196;

export const referenceForecastScenarioId = 1;

export const defaultGlobalImpactAgeGroupId = allAgesGroupId;
export const defaultGlobalImpactGenderId = bothGenderId;
export const defaultGlobalImpactMeasureId = dalyMeasureId;
export const defaultGlobalImpactMetricId = rateMetricId;
export const defaultGlobalImpactCauseId = hivAidsCauseId;
export const defaultGlobalImpactYear = 2016;

export const defaultTrendsAgeGroupId = allAgesGroupId;
export const defaultTrendsGenderId = allGendersId;
export const defaultTrendsMeasureId = incidenceMeasureId;
export const defaultTrendsMetricId = numberMetricId;
export const defaultTrendsCauseId = hivAidsCauseId;
export const defaultTrendsLocationId = globalLocationId;
export const defaultTrendsForecastScenarioId = referenceForecastScenarioId;

export const defaultSpendingScenariosMetricId = rateMetricId;
export const defaultSpendingScenariosMeasureId = incidenceMeasureId;
export const defaultSpendingScenariosLocationId = southAfricaLocationId;

export const defaultGlobalSpendingYear = 2017;

export const defaultAgeGroupId = allAgesGroupId;
export const defaultGenderId = allGendersId;
export const defaultMeasureId = deathMeasureId;
export const defaultMetricId = numberMetricId;
export const defaultCauseId = hivAidsCauseId;
export const defaultLocationId = USALocationId;
export const defaultForecastScenarioId = referenceForecastScenarioId;
export const defaultYear = 2018;
export const defaultSpendScenarioId = 1;
export const defaultSpendCategoryId = '2,10,12,15';
export const defaultSpendLocationId = '64,103,137,31,166,158,4';
export const defaultBurdenMeasureId = 5;
export const defaultBurdenMetricId = 3;
