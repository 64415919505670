import React from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import numeral from 'numeral';
import find from 'lodash/find';
import flatten from 'lodash/flatten';
import groupBy from 'lodash/groupBy';
import uniq from 'lodash/uniq';

import _ from '@ihme/common/locale';
import styled from '@ihme/common/theme/styled';
import {
    ChartDropdown,
    ChartProvider,
    Clearfix,
    Col,
    Form,
    LineChart,
    PageHeader,
} from '@ihme/common/web/components';
import echartsTheme from '@ihme/common/theme/echarts-theme';

import config from '../../config';
import locale from './locale';
import api from '../../api';
import { loadHivGbdFilters, loadLocations } from '../../store/data/actions';
import { getHivGbdFilters, getLocations } from '../../store';

import { sortLocations } from '../../utility/sort-helpers';

const propTypes = {
    history: ReactRouterPropTypes.history.isRequired,
    location: ReactRouterPropTypes.location.isRequired,

    locations: PropTypes.array,
    loadLocations: PropTypes.func.isRequired,

    hivGbdFilters: PropTypes.object,
    loadHivGbdFilters: PropTypes.func.isRequired,
};

const DEFAULT_FILTERS = {
    age_group_id: config.defaultTrendsAgeGroupId,
    gender_id: config.defaultTrendsGenderId,
    measure_id: config.defaultTrendsMeasureId,
    metric_id: config.defaultTrendsMetricId,
    cause_id: config.defaultTrendsCauseId,
    location_id: config.defaultTrendsLocationId,
    forecast_scenario_id: config.defaultTrendsForecastScenario,
};

const ButtonGroup = styled('div')(({ theme }) => ({
    '> *': {
        width: 124,
        marginRight: 12,
        [theme.breakpoint.xs]: {
            width: 252,
        },
    },
}));

class TrendsScene extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            filters: {},
        };
    }

    componentWillMount() {
        if (!this.props.hivGbdFilters) {
            this.props.loadHivGbdFilters();
        }

        if (!this.props.locations) {
            this.props.loadLocations();
        }
    }

    // restore filters from URL Params
    getInitialFilters = () => queryString.parse(this.props.location.search);

    loadData = params => new Promise((resolve, reject) =>
        api.data.getHivGbdRecords(params)
            .then(response => {
                let {
                    columns,
                    records,
                } = response;

                const genderIndex = columns.indexOf('gender_id');

                const genderIds = uniq(records.map(record => record[genderIndex]));

                const valueIndex = columns.indexOf('mean');
                const yearIndex = columns.indexOf('year');
                const upperIndex = columns.indexOf('upper');
                const lowerIndex = columns.indexOf('lower');

                const dataByGender = groupBy(records, record => record[genderIndex]);

                const reference = dataByGender[genderIds[0]];

                columns.push(...flatten(genderIds.map(genderId => [
                    `gender_${genderId}_value`,
                    `gender_${genderId}_upper`,
                    `gender_${genderId}_lower`,
                ])));

                // merge genders data
                records = reference.map(record =>
                    [
                        ...record,
                        ...flatten(
                            genderIds.map(genderId => {
                                const genderObj = find(dataByGender[genderId],
                                    genderRecord => genderRecord[yearIndex] === record[yearIndex]);

                                return [genderObj[valueIndex], genderObj[upperIndex], genderObj[lowerIndex]];
                            })),
                    ]);

                resolve({ columns, records });
            })
            .catch(reject),
    );

    generateLegendData = () => {
        const { gender_id } = this.state.filters;
        if (!gender_id) {
            return [];
        }

        return gender_id.toString()
            .split(',')
            .map(id => ({
                    name: _(`gender_${id}`),
                    icon: 'circle',
                }
            ));
    };

    renderTooltip = (params, { filters, isMobileView }) => {
        const tooltipFontSize = isMobileView ? 10 : 14;

        const getMarker = color =>
            `<span style="display:inline-block;margin-right:8px;border-radius:10px;width:10px;height:10px;background-color:${color};"></span>`;

        const formatValue = value =>
            value > 1
                ? numeral(value).format('0,0.0')
                : value;

        return `<div style="text-align:left;font-size:${tooltipFontSize}px">` +
            params.map(obj =>
                getMarker(obj.color.colorStops[0].color) +
                _(`trends_tooltip_measure_${this.state.filters.measure_id}`, {
                    gender: obj.seriesName,
                    value: `<b>${formatValue(obj.value)}</b>`,
                    cause: _(`cause_${this.state.filters.cause_id}`),
                    year: obj.axisValue,
                    separator: '<br/>&nbsp;&nbsp;&nbsp;&nbsp;',
                }))
                .join('<br/>') + '</div>';
    };

    onFiltersChange = (updatedFilters, filters) =>
        this.setState({ filters });

    getSelectedGenderIds = () =>
        this.state.filters && this.state.filters.gender_id
            ? this.state.filters.gender_id
                .toString()
                .split(',')
            : [];

    getYAxisKeys = () =>
        this.getSelectedGenderIds()
            .map(id =>
                `gender_${id}_value`);

    getGenderCSVKeys = () =>
        flatten(
            this.getSelectedGenderIds()
                .map(id => [
                    `gender_${id}_value`,
                    `gender_${id}_upper`,
                    `gender_${id}_lower`,
                ]));

    getGenderCSVHeaders = () =>
        flatten(
            this.getSelectedGenderIds()
                .map(id => [
                    `gender_${id}_value_csv_header`,
                    `gender_${id}_upper_csv_header`,
                    `gender_${id}_lower_csv_header`,
                ]))
            .map(key => _(key));

    getLineParams = () =>
        this.state.filters && this.state.filters.gender_id
            ? this.state.filters.gender_id
                .toString()
                .split(',')
                .map((genderId, i) =>
                    ({
                        name: _(`gender_${genderId}`),
                        color: echartsTheme.line.colors[i],
                    }))
            : [];

    getSaveFilename = ({ filters, yearsRange }) => 'Trends, '
        + _(`cause_${filters.cause_id}`) + ' '
        + _(`measure_${filters.measure_id}`) + ' '
        + _(`metric_${filters.metric_id}`) + ' in '
        + _(`location_${filters.location_id}`) + ', '
        + _(`age_group_${filters.age_group_id}`) + ', '
        + _(`gender_${filters.gender_id}`) + ', '
        + yearsRange.range;

    render() {
        const {
            hivGbdFilters,
            locations,
        } = this.props;

        return (
            <React.Fragment>
                <PageHeader>{_(locale.title)}</PageHeader>
                <Col xs={12} md={9} mdPush={3}>
                    <ChartProvider
                        key={hivGbdFilters}
                        loadFilters={() => Promise.resolve(hivGbdFilters)}
                        loadData={this.loadData}
                        defaultFilters={DEFAULT_FILTERS}
                        initialFilters={this.getInitialFilters()}
                        onFiltersChange={this.onFiltersChange}
                        history={this.props.history}
                        location={this.props.location}>
                        <Form inline>
                            <ButtonGroup>
                                <ChartDropdown
                                    label={_(locale.causesTitle)}
                                    localePrefix="cause_"
                                    filterKey="cause_id"
                                    isSearchable
                                />
                                <ChartDropdown
                                    label={_(locale.metricsTitle)}
                                    localePrefix="metric_"
                                    filterKey="metric_id"
                                />
                                <ChartDropdown
                                    label={_(locale.measuresTitle)}
                                    localePrefix="measure_"
                                    filterKey="measure_id"
                                />
                                <ChartDropdown
                                    label={_(locale.ageGroupsTitle)}
                                    localePrefix="age_group_"
                                    filterKey="age_group_id"
                                />
                                <ChartDropdown
                                    label={_(locale.gendersTitle)}
                                    localePrefix="gender_"
                                    filterKey="gender_id"
                                />
                                <ChartDropdown
                                    label={_(locale.locationsTitle)}
                                    localePrefix="location_"
                                    filterKey="location_id"
                                    preprocessOptions={params => sortLocations(params, locations)}
                                    isSearchable
                                />
                            </ButtonGroup>
                            <Clearfix/>
                        </Form>

                        <LineChart
                            withSlider
                            saveAsImage={{ visible: true, filename: this.getSaveFilename }}
                            saveAsCSV={{
                                visible: true,
                                filename: this.getSaveFilename,
                                headers: ['Location', 'Year', 'Unit', 'Measure', 'Cause', 'Age', ...this.getGenderCSVHeaders()],
                                keys: ['location_id', 'year', 'metric_id', 'measure_id', 'cause_id', 'age_group_id', ...this.getGenderCSVKeys()],
                            }}
                            yAxisKeys={this.getYAxisKeys()}
                            lineParams={this.getLineParams()}
                            renderTooltip={this.renderTooltip}
                            renderTitle={({ filters, yearsRange }) =>
                                _(`cause_${filters.cause_id}`) + ' '
                                + _(`measure_${filters.measure_id}`) + ' '
                                + _(`metric_${filters.metric_id}`) + ' in '
                                + _(`location_${filters.location_id}`) + ' '
                                + yearsRange.range}
                            renderSubtitle={({ filters }) => _(`age_group_${filters.age_group_id}`)}
                            generateLegendData={this.generateLegendData}
                        />
                    </ChartProvider>
                </Col>
                <Col xs={12} md={3} mdPull={9}>
                    <p dangerouslySetInnerHTML={{ __html: _(locale.highlightText) }}/>
                </Col>
            </React.Fragment>
        );
    }
}

TrendsScene.propTypes = propTypes;

const mapStateToProps = state => {
    const storedFilters = getHivGbdFilters(state);
    const hivGbdFilters = storedFilters !== null
        ? {
            ...storedFilters,
            gender_id: [...storedFilters.gender_id, config.allGendersId],
        }
        : null;

    return {
        locations: getLocations(state),
        hivGbdFilters,
    };
};

export default compose(
    withRouter,
    connect(mapStateToProps, {
        loadLocations,
        loadHivGbdFilters,
    }),
)(TrendsScene);
