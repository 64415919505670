import React from 'react';

import _ from '@ihme/common/locale';
import { Card, Col, FlexRow, InternalLink } from '@ihme/common/web/components';
import styled from '@ihme/common/theme/styled';

import locale from './locale';
import { GLOBAL_IMPACT_PATH, TRENDS_PATH } from '../../../router/paths';

const View = styled.div(props => ({
    marginTop: 40,
    marginBottom: 40,
    [props.theme.breakpoint.xs]: {
        marginTop: 20,
        marginBottom: 20,
    },
}));

const StyledCol = styled(Col)(() => ({
    '-webkitFlex': '4 0 15em',
}));

const StyledInternalLink = styled(InternalLink)(props => ({
    display: 'block',
    height: '100%',
    textDecoration: 'none',
    borderRadius: 4,
    marginBottom: 20,
}));

class ChartList extends React.Component {
    getData = () => [
        {
            key: 0,
            path: TRENDS_PATH,
            image: '/img/trends_card.png',
            title: _(locale.trendsTitle),
            text: _(locale.trendsText),
        },
        {
            key: 1,
            path: GLOBAL_IMPACT_PATH,
            image: '/img/global_impact_card.png',
            title: _(locale.globalImpactTitle),
            text: _(locale.globalImpactText),
        },
        /*{
            key: 2,
            path: GLOBAL_SPENDING_PATH,
            image: '/img/global_spending_card.png',
            title: _(locale.globalSpendingTitle),
            text: _(locale.globalSpendingText),
        },*/
    ];

    render() {
        return (
            <View>
                <FlexRow wrapAtBreakpoint="sm">
                    {this.getData().map(item => (
                        <StyledCol xs={12} sm={6} key={item.key}>
                            <StyledInternalLink href={item.path}>
                                <Card {...item}>
                                    <p>{item.text}</p>
                                </Card>
                            </StyledInternalLink>
                        </StyledCol>
                    ))}
                </FlexRow>
            </View>
        );
    }
}

export default ChartList;
