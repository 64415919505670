import { combineReducers } from 'redux';

import data, * as dataSelectors from './data/reducer';

export const rootReducer = combineReducers({
    data,
});

export const getBurdenForecastFilters = state => dataSelectors.getBurdenForecastFilters(state.data);
export const getBurdenForecastsWithTHEFilters = state => dataSelectors.getBurdenForecastsWithTHEFilters(state.data);
export const getSpendingForecastFilters = state => dataSelectors.getSpendingForecastFilters(state.data);
export const getHivGbdFilters = state => dataSelectors.getHivGbdFilters(state.data);
export const getCauses = state => dataSelectors.getCauses(state.data);
export const getLocations = state => dataSelectors.getLocations(state.data);
export const getSpendScenarios = state => dataSelectors.getSpendScenarios(state.data);
export const getSpendCategories = state => dataSelectors.getSpendCategories(state.data);
