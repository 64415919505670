export default {
    title: 'trends_scene_title',
    highlightText: 'trends_highlight_text',
    causesTitle: 'filter_cause_title',
    metricsTitle: 'filter_metric_title',
    measuresTitle: 'filter_measure_title',
    ageGroupsTitle: 'filter_age_group_title',
    gendersTitle: 'filter_gender_title',
    locationsTitle: 'filter_location_title',
};
