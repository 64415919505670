import * as types from './types';
import api from '../../api';

export const loadHivGbdFilters = () => dispatch =>
    api.data.getHivGbdFilters()
        .then(filters => {
            dispatch({
                type: types.HIV_GBD_FILTERS_LOADED,
                payload: filters,
            });

            return filters;
        });

export const loadCauses = () => dispatch =>
    api.data.getCauses('', 1, 1000)
        .then(data => {
            dispatch({
                type: types.CAUSES_LOADED,
                payload: data.results,
            });

            return data;
        });

export const loadLocations = () => dispatch =>
    api.data.getLocations()
        .then(data => {
            dispatch({
                type: types.LOCATIONS_LOADED,
                payload: data,
            });

            return data;
        });

export const loadBurdenForecastFilters = () => dispatch =>
    api.data.getBurdenForecastFilters()
        .then(filters => {
            dispatch({
                type: types.BURDEN_FORECAST_FILTERS_LOADED,
                payload: filters,
            });

            return filters;
        });

export const loadBurdenForecastsWithTHEFilters = () => dispatch =>
    api.data.getBurdenForecastsWithTHEFilters()
        .then(filters => {
            dispatch({
                type: types.BURDEN_FORECASTS_WITH_THE_FILTERS_LOADED,
                payload: filters,
            });

            return filters;
        });

export const loadSpendingForecastFilters = () => dispatch =>
    api.data.getSpendingForecastFilters()
        .then(filters => {
            dispatch({
                type: types.SPENDING_FORECAST_FILTERS_LOADED,
                payload: filters,
            });

            return filters;
        });

export const loadSpendScenarios = () => dispatch =>
    api.getSpendScenarios()
        .then(filters => {
            dispatch({
                type: types.SPEND_SCENARIOS_LOADED,
                payload: filters,
            });

            return filters;
        });

export const loadSpendCategories = () => dispatch =>
    api.getSpendCategories()
        .then(filters => {
            dispatch({
                type: types.SPEND_CATEGORIES_LOADED,
                payload: filters,
            });

            return filters;
        });
