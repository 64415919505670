import PropTypes from 'prop-types';
import React from 'react';

import styled from '@ihme/common/theme/styled';

const propTypes = {
    children: PropTypes.node,
};

const View = styled.div(props => ({
    color: '#333333',
    display: 'flex',
    fontFamily: 'Helvetica, sans-serif',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '16px',
    borderRadius: '4px',
    backgroundColor: '#ffffff',
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2)',
    padding: '20px 20px 20px 30px',
    marginBottom: '20px',
    ':hover': {
        backgroundColor: '#f5f7f8',
    },
}));

const Text = styled.div();

const Image = styled.img(props => ({ paddingLeft: 10 }));

const NewsItem = ({ children }) => (
    <View>
        <Text>{children}</Text>
        <Image src="/img/ic-arrow.png" alt="Right Arrow"/>
    </View>
);

NewsItem.propTypes = propTypes;

export default NewsItem;
