export default {
    title: 'highlights_title',
    more: 'highlights_more',
    less: 'highlights_less',

    whatIsHepTitle: 'highlights_what_is_hep_title',
    whatIsHepDescription: 'highlights_what_is_hep_description',
    whatIsHepText: 'highlights_what_is_hep_text',

    fundingTitle: 'highlights_global_goals_title',
    fundingDescription: 'highlights_global_goals_description',
    fundingText: 'highlights_global_goals_text',

    burdenTitle: 'highlights_burden_risk_trends_title',
    burdenDescription: 'highlights_burden_risk_trends_description',
    burdenText: 'highlights_burden_risk_trends_text',
};
